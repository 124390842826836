import { Component, Input, OnInit } from '@angular/core';
import { PipeClassViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
    selector: 'app-piping-components',
    styleUrls: ['piping-components.component.scss'],
    templateUrl: './piping-components.component.html',
})
export class PipingComponentsComponent implements OnInit{
    @Input() pipeClassViewModelComponents: PipeClassViewModel["componentsInPipeClass"];

    //... ['COMPONENT_GROUP_NAME', 'DISPLAY_NAME'] ...
    private orderedComponentGroups = [
        ['Pipe', 'Pipes'],
        ['Nipples/Swages','Nipples/Swages'],
        ['Fittings', 'Fittings'],
        ['Tube Fittings', 'Tube Fittings'],
        ['Flanges', 'Flanges'],
        ['Blanks and Spacers', 'Blanks and Spacers'],
        ['Gaskets', 'Gaskets'],
        ['Bolts', 'Bolts'],
        ['MISC', 'MISC']
    ];

    protected nonEmptyComponentGroups: string[][];

    ngOnInit(): void {
        this.nonEmptyComponentGroups = this.orderedComponentGroups.filter(componentGroup => this.getComponentsByGroup(componentGroup[0]).length > 0)
    }
    
    getComponentsByGroup(group: string) {
        if (this.pipeClassViewModelComponents && this.pipeClassViewModelComponents.length > 0 ) {
            return this.pipeClassViewModelComponents.filter(componentInPipeClass => componentInPipeClass.component.group == group);
        }
        return [];
    }
}
